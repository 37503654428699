import {gql, useQuery} from '@apollo/client'

const useGetPaymentMethod = ({variables, skip}) => {
  const {data, error, loading, refetch} = useQuery(
    gql`
      query($paymentMethodId: String!) {
        getPaymentMethodInfo(paymentMethodId: $paymentMethodId) {
          last4
          brand
          expYear
          expMonth
          fingerPrint
          __typename
        }
      }
    `,
    {
      variables,
      skip,
    },
  )

  return {data, error, loading, refetch}
}

export default useGetPaymentMethod
