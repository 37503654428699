import React from 'react'
import useTranslation from '../../../../i18n/useTranslation'
import ZelleIcon from '../../../../assets/zelle-icon.png'
import iconVisa from '../../../../assets/card-visa.png'
import iconMastercard from '../../../../assets/card-mastercard.png'
import iconDiners from '../../../../assets/card-diners.png'
import iconDiscover from '../../../../assets/card-discover.png'
import bankIcon from '../../../../assets/bank.png'
import iconAmex from '../../../../assets/card-amex.png'
import iconCardUndefined from '../../../../assets/card-undefined.png'
import creditCardIcon from '../../../../assets/credit-card-icon.png'

const paymentMethodKeys = {
  Card: 'CardPaymentMethod',
  BankDebit: 'BankDebitPaymentMethod',
  Manual: 'ManualPaymentMethod',
  Zelle: 'ZellePaymentMethod',
  // BankCredit: 'BankCreditPaymentMethod',
}
const useChangePaymentMethod = () => {
  const {translate} = useTranslation()
  const getIcon = (icon) => {
    switch (icon) {
      case 'visa':
        return iconVisa
      case 'mastercard':
        return iconMastercard
      case 'diners':
        return iconDiners
      case 'amex':
        return iconAmex
      case 'discover':
        return iconDiscover
      default:
        return iconCardUndefined
    }
  }

  const PaymentLabel = ({current}) => {
    let label
    console.log('current', current)
    switch (current?.__typename) {
      case paymentMethodKeys.Card:
        label = (
          <>
            <div>
              {translate('home.onboard.payment.creditCard')}{' '}
              {current?.cardInfo?.last4}
            </div>
            <img
              src={getIcon(current?.cardInfo?.brand)}
              width={32}
              style={{marginLeft: 7}}
              alt='zelle'
            />
          </>
        )
        break
      case paymentMethodKeys.Manual:
        label = (
          <>
            <div>{translate('home.onboard.payment.transferNonUsd')}</div>
            <img
              src={bankIcon}
              width={24}
              style={{marginLeft: 7}}
              alt='zelle'
            />
          </>
        )
        break
      /** case paymentMethodKeys.BankCredit:
            label = `${translate('home.onboard.payment.transferUsd')}`
            break**/
      case paymentMethodKeys.BankDebit:
        label = (
          <>
            <div>
              {translate('home.onboard.payment.debitCard')} -{' '}
              {current?.accountInfo?.bankName.toUpperCase()}{' '}
              {current?.accountInfo?.last4}
            </div>
            <img
              src={bankIcon}
              width={24}
              style={{marginLeft: 7}}
              alt='zelle'
            />
          </>
        )
        break
      case paymentMethodKeys.Zelle:
        label = (
          <>
            <div>{translate('home.onboard.payment.zelle')}</div>
            <img
              src={ZelleIcon}
              width={24}
              style={{marginLeft: 7}}
              alt='zelle'
            />
          </>
        )
        break
      default:
        label = (
          <>
            <div>{translate('home.onboard.payment.nodefined')}</div>
            <img
              src={creditCardIcon}
              width={24}
              style={{marginLeft: 7}}
              alt='zelle'
            />
          </>
        )
    }
    return <div className='d-flex flex-row align-items-center'>{label}</div>
  }
  return {
    PaymentLabel,
    paymentMethodKeys,
  }
}

export default useChangePaymentMethod
