import {useQuery, gql} from '@apollo/client'

const useGetPaymentMethodFromUser = ({ variables, skip }) => {
  const { data, error, loading, refetch } = useQuery(
    gql`
      query($id: String!) {
        user(id: $id) {
          paymentMethod {
            ... on CardPaymentMethod {
              id
              cardInfo {
                last4
                brand
                expYear
                expMonth
              }
            }
            ... on BankDebitPaymentMethod {
              kind
              validationStatus
              accountInfo {
                bankName
                last4
              }
            }
            ... on ManualPaymentMethod {
              manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
              }
            }
            ... on BankCreditPaymentMethod {
              kind
              status
              sourceInfo {
                accountNumber
                routingNumber
                bankName
                swiftCode
              }
            }
            ... on ZellePaymentMethod {
              kind
              zellePaymentInfo {
                emailToSend
              }
            }
          }
          registeredPaymentMethods {
            id
            kind
            ... on CardPaymentMethod {
              cardInfo {
                last4
                brand
                expYear
                expMonth
                fingerPrint
                __typename
              }
              __typename
            }
            ... on BankDebitPaymentMethod {
              validationStatus
              accountInfo {
                bankName
                last4
                __typename
              }
              __typename
            }
            ... on BankCreditPaymentMethod {
              status
              sourceInfo {
                bankName
                accountNumber
                swiftCode
                routingNumber
                __typename
              }
              __typename
            }
            ... on ManualPaymentMethod {
              manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
                __typename
              }
              __typename
            }
            ... on ZellePaymentMethod {
              kind
              zellePaymentInfo {
                emailToSend
                __typename
              }
              __typename
            }
            __typename
          }
        }
      }
    `,
    {
      variables,
      skip,
    },
  )

  return {data: data?.user, loading, refetch, error}
}

export default useGetPaymentMethodFromUser
