import React, {useState, useCallback, useEffect} from 'react'
import {
  Modal,
  IconClose,
  useWindowDimensions,
  ListOption,
  StickyButton,
  CarouselFrame,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import {usePlaidLink} from 'react-plaid-link'
import zelleIconBw from '../../../assets/zelle-icon-bw.png'
import bankIcon from '../../../assets/bank.png'
import creditCardIcon from '../../../assets/credit-card-icon.png'
import iconVisa from '../../../assets/card-visa.png'
import iconMastercard from '../../../assets/card-mastercard.png'
import iconDiners from '../../../assets/card-diners.png'
import iconDiscover from '../../../assets/card-discover.png'
import iconAmex from '../../../assets/card-amex.png'
import iconCardUndefined from '../../../assets/card-undefined.png'

const paymentMethodKeys = {
  Card: 'CardPaymentMethod',
  BankDebit: 'BankDebitPaymentMethod',
  Manual: 'ManualPaymentMethod',
  BankCredit: 'BankCreditPaymentMethod',
  Zelle: 'ZellePaymentMethod',
}

const ChangePaymentMethod = (props) => {
  const {
    paymentMethod,
    keyPlaid,
    onChange,
    mutationSetBankDebitData,
    isAdmin,
    primary = false,
    style = {},
    registeredPaymentMethods = [],
  } = props
  const [isOpen, setOpen] = useState(false)
  const toggle = () => {
    setOpen(!isOpen)
  }

  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()

  // PLAID
  const onSuccess = useCallback((token, metadata) => {
    // send token to server
    mutationSetBankDebitData({
      variables: {
        accountId: metadata.accounts[0].id,
        plaidPublicToken: token,
        institutionId: metadata.institution.institution_id,
      },
    })
    // eslint-disable-next-line
  }, [])

  const config = {
    token: keyPlaid,
    onSuccess,
    // ...
  }

  const {open} = usePlaidLink(config)
  const [paymentMethods, setPaymentMethods] = useState([
    {
      // CREDIT CARD
      value: paymentMethodKeys.Card,
      text: translate('home.onboard.payment.credit'),
      icon: <img src={creditCardIcon} alt='credit card' width={24} />,
    },
    {
      // TRANSFER
      value: paymentMethodKeys.Manual,
      text: translate('home.onboard.payment.transferNonUsd'),
      icon: <img src={bankIcon} width='24' alt='bank' />,
    },
    {
      // ZELLE
      value: paymentMethodKeys.Zelle,
      text: translate('home.onboard.payment.zelle'),
      icon: <img src={zelleIconBw} width='24' alt='zelle' />,
    },
  ])


  const getIconCard = (brand) => {
    switch (brand) {
      case 'visa':
        return iconVisa;
      case 'mastercard':
        return iconMastercard;
      case 'diners':
        return iconDiners;
      case 'amex':
        return iconAmex;
      case 'discover':
        return iconDiscover;
      default:
        return iconCardUndefined;
    }
  };

  useEffect(() => {
    if (!isAdmin) {
      setPaymentMethods([
        ...paymentMethods,
        {
          // DEBITO - PLAID
          value: paymentMethodKeys.BankDebit,
          text: translate('home.onboard.payment.debit'),
          icon: <img src={creditCardIcon} alt='credit card' width={24} />,
        },
      ])
    }
    if (registeredPaymentMethods?.length > 0) {
      const uniquePaymentMethods = [
        ...new Map(
          [
            ...paymentMethods,
            ...registeredPaymentMethods.map((userMethod) => ({
              value: userMethod?.id,
              text: `**** **** ${userMethod?.cardInfo?.last4} ${userMethod?.cardInfo?.expMonth}/${userMethod?.cardInfo?.expYear}`,
              icon: (
                <img
                  src={getIconCard(userMethod?.cardInfo?.brand)}
                  alt='credit-card'
                  width={24}
                />
              ),
            })),
          ].map((method) => [method.value, method]),
        ).values(),
      ]
      console.log('uniquePaymentMethods', uniquePaymentMethods)
      setPaymentMethods(uniquePaymentMethods)
    }
    // eslint-disable-next-line
  }, [registeredPaymentMethods])

  const [currentMethod, setCurrentMethod] = useState(paymentMethod)
  useEffect(() => {
    if (isOpen) setCurrentMethod(paymentMethod)
    // eslint-disable-next-line
  }, [isOpen])


  const buttonProp = primary ? {color: 'primary'} : {}
  return (
    <>
      <div className='m-0 p-0 w-100' style={style} onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex mb-0 px-5'>
            <div className='d-flex flex-column justify-content-start mr-4'>
              <span className='text-title font-weight-bold h2'>
                {translate('home.onboard.payment.changeMethod')}
              </span>
            </div>
            <div className='pt-1'>
              <IconClose
                className='d-flex align-self-start'
                size={20}
                onClick={toggle}
              />
            </div>
          </div>
        }
      >
        <div className='m-0 p-0 mx-5 mt-0'>
          <div className='m-0 px-0 pb-4 pt-0'>
            {paymentMethods.map((method) => (
              <ListOption
                className={`mb-2 ${
                  method.value === currentMethod
                    ? `selected${primary ? '' : '-secondary'}`
                    : ''
                }`}
                id='x-0'
                label={null}
                icon={
                  <div
                    title={`${method.text}`}
                    className='d-flex align-items-center text-muted m-0'
                  >
                    {method.icon !== '' && method.icon}
                    <span className='ml-2' style={{whiteSpace: 'nowrap'}}>
                      {method.text}
                    </span>
                  </div>
                }
                onClick={() => {
                  setCurrentMethod(method.value)
                }}
              />
            ))}
          </div>
          {currentMethod !== paymentMethod && (
            <CarouselFrame type='animation'>
              <span
                style={{whiteSpace: 'nowrap'}}
                title={translate('home.onboard.payment.confirmChangeMethodMsg')}
                className='text-error h45 mb-4'
              >
                {translate('home.onboard.payment.confirmChangeMethodMsg')}
              </span>
            </CarouselFrame>
          )}
          <StickyButton
            condition
            label={translate('home.onboard.approve.before.continue')}
            onClickButtonFunction={() => {
              onChange(currentMethod, () => {
                open()
              })
              // setPaymentMethod(currentMethod)
              toggle()
            }}
            {...buttonProp}
          />
        </div>
      </Modal>
    </>
  )
}

ChangePaymentMethod.propTypes = {}

ChangePaymentMethod.defaultProps = {}

export default ChangePaymentMethod
